var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Add Image "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE2.src = _vm.Tabimage
                            _vm.addForm.image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block  mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addForm.image),callback:function ($$v) {_vm.$set(_vm.addForm, "image", $$v)},expression:"addForm.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.addForm.alt_image),callback:function ($$v) {_vm.$set(_vm.addForm, "alt_image", $$v)},expression:"addForm.alt_image"}})],1)],1)],1)],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"In Gallery Order","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Gallery Order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addForm.order),callback:function ($$v) {_vm.$set(_vm.addForm, "order", $$v)},expression:"addForm.order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"In Slider Order\n","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"In Slider Order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addForm.slider_order),callback:function ($$v) {_vm.$set(_vm.addForm, "slider_order", $$v)},expression:"addForm.slider_order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }